import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Paper,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BatchesContext } from "../../../services/Batches/Batches.context";
import { UsersContext } from "../../../services/users/users.context";
import { FaUsersViewfinder } from "react-icons/fa6";
import _ from "lodash";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";

const errors = {
  nameRequired: "Batch name required",
  codeRequired: "Batch code required",
};

const commonInputFieldProps = {
  value: "",
  focused: false,
  error: false,
  errorMessage: "",
};

const defaultInputState = {
  name: {
    ...commonInputFieldProps,
  },
  code: {
    ...commonInputFieldProps,
  },

  id: {
    ...commonInputFieldProps,
  },
};

const CreateEditBatch = ({ mode }) => {
  const [batch, setBatch] = useState(null);
  const [inputs, setInputs] = useState(defaultInputState);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [students, setStudents] = useState([]);
  const [orgStudents, setOrgStudents] = useState([]);

  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedTrainers, setSelectedTrainers] = useState([]);

  const [trainers, setTrainers] = useState([]);
  const [orgTrainers, setOrgTrainers] = useState([]);
  const { onCreateBatch, onGetBatch, onEditBatch } = useContext(BatchesContext);
  const { onGetGroupedUsersByRoles } = useContext(UsersContext);
  const [searchKeyword, setSearchKeyword] = useState({
    type: "",
    student: "",
    trainer: "",
  });

  const [studentPage, setStudentPage] = useState(0);
  const [studentRowsPerPage, setStudentRowsPerPage] = useState(5);
  const [trainerPage, setTrainerPage] = useState(0);
  const [trainerRowsPerPage, setTrainerRowsPerPage] = useState(5);

  const navigate = useNavigate();

  useEffect(() => {
    getStudentsAndTrainers();
    if (mode) {
      let title = mode === "edit" ? "Edit Batch" : "Add New Batch";
      document.title = title;
    }
    if (mode === "edit") {
      let editId = searchParams.get("id");
      if (!editId) {
        navigate("/dashboard/batches");
        return;
      }
      if (editId) {
        onGetBatch(
          editId,
          (result) => {
            let batchData = result.batch;
            setBatch(batchData);
            if (batchData) {
              let { name, code, students, trainers, _id } = batchData;
              let selStudents = students.map((s) => s._id);
              let selTrainers = trainers.map((s) => s._id);
              setSelectedStudents(selStudents);
              setSelectedTrainers(selTrainers);

              setInputs((prevState) => ({
                ...prevState,
                name: {
                  ...commonInputFieldProps,
                  value: name,
                },
                code: {
                  ...commonInputFieldProps,
                  value: code,
                },
                id: {
                  value: _id,
                },
              }));
            } else {
              navigate("/dashboard/batches");
            }
          },
          () => {
            navigate("/dashboard/batches");
          },
          false,
          false
        );
      }
    }
  }, [mode]);

  useEffect(() => {
    if (students?.length > 0 && selectedStudents?.length > 0) {
      onSortDataBySelected("student", students);
    }
    if (trainers?.length > 0 && selectedTrainers?.length > 0) {
      onSortDataBySelected("trainer", trainers);
    }
  }, [selectedStudents, selectedTrainers, orgStudents, orgTrainers]);

  const onSortDataBySelected = (role, data) => {
    if (role === "student") {
      let sortedStudents = _.sortBy(data, (student) =>
        selectedStudents.includes(student._id) ? 0 : 1
      );
      setStudents(sortedStudents);
    }
    if (role === "trainer") {
      let sortedTrainers = _.sortBy(data, (trainer) =>
        selectedTrainers.includes(trainer._id) ? 0 : 1
      );
      setTrainers(sortedTrainers);
    }
  };

  const onValueChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: false,
        errorMessage: "",
        value,
      },
    }));
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    let hadErrors = false;
    const setErrorMessage = (name, message) => {
      setInputs((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          error: true,
          errorMessage: message,
        },
      }));
      hadErrors = true;
    };
    const returnValue = (value) => {
      return typeof value === "string" ? value?.trim() : value;
    };
    let { name, code } = inputs;
    name = returnValue(name.value);
    code = returnValue(code.value);

    if (!name) {
      setErrorMessage("name", errors.nameRequired);
    }
    if (!code) {
      setErrorMessage("code", errors.codeRequired);
    }

    if (hadErrors) {
      return;
    }
    let data = {
      name: name,
      code: code,
      students: selectedStudents,
      trainers: selectedTrainers,
    };

    if (mode === "edit") {
      onEditBatch(inputs.id.value, data, () => {
        navigate("/dashboard/batches");
      });
    } else {
      onCreateBatch(data, () => {
        navigate("/dashboard/batches");
      });
    }
  };

  const getStudentsAndTrainers = () => {
    onGetGroupedUsersByRoles(
      (result) => {
        let data = result.data;
        if (data) {
          let trainers = data.trainers;
          let students = data.students;
          setTrainers(trainers);
          setOrgTrainers(trainers);
          setStudents(students);
          setOrgStudents(students);
        }
      },
      false,
      false
    );
  };

  const onClickCheckbox = (role, userId) => {
    let users = role === "trainer" ? selectedTrainers : selectedStudents;
    let updatedUsers;
    if (users.includes(userId)) {
      updatedUsers = users.filter((id) => id !== userId);
    } else {
      updatedUsers = [...users, userId];
    }

    if (role === "trainer") {
      setSelectedTrainers(updatedUsers);
    } else {
      setSelectedStudents(updatedUsers);
    }
  };

  const onChangeSearchKeyword = (type, e) => {
    let value = e.target.value;
    let data =
      type === "student" ? orgStudents : type === "trainer" ? orgTrainers : [];
    let obj = {
      type: type,
      student: type === "student" ? value : "",
      trainer: type === "trainer" ? value : "",
    };
    setSearchKeyword(obj);
    let filtered = [];

    if (type === "student") {
      filtered = data;
    } else if (type === "trainer") {
      filtered = data;
    }

    if (value) {
      value = value.toLowerCase();
      let finalData = _.cloneDeep(data);
      filtered = finalData.filter((d) => {
        let { email, firstName, lastName } = d;
        let emailFound = email.toLowerCase().includes(value);
        let firstNameFound = firstName.toLowerCase().includes(value);
        let lastNameFound = lastName.toLowerCase().includes(value);
        return emailFound || firstNameFound || lastNameFound;
      });
    }

    if (type === "student") {
      onSortDataBySelected("student", filtered);
    } else if (type === "trainer") {
      onSortDataBySelected("trainer", filtered);
    }
  };

  const handleChangePage = (event, type, newPage) => {
    if (type === "student") {
      setStudentPage(newPage);
    } else {
      setTrainerPage(newPage);
    }
  };
  const handleChangeRowsPerPage = (event, type) => {
    let value = event.target?.value;
    if (type === "student") {
      setStudentRowsPerPage(parseInt(value));
      setStudentPage(0);
    } else {
      setTrainerRowsPerPage(parseInt(value));
      setTrainerPage(0);
    }
  };

  const [sort, setSort] = useState({
    type: "desc",
    field: null,
  });

  return (
    <section>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {mode === "create" ? "Add New Batch " : "Edit Batch"}
          </Typography>
          <br />
          <Box
            component="form"
            noValidate
            onSubmit={onSubmitForm.bind(this)}
            sx={{ mt: 2 }}
          >
            <Grid container spacing={2}>
              {/* for form */}

              {/* name */}
              <Grid item md={6}>
                <TextField
                  error={inputs.name.error}
                  helperText={inputs.name.errorMessage}
                  margin="normal"
                  placeholder="Enter Batch Name "
                  required
                  fullWidth
                  id="name"
                  label="Batch Name"
                  name="name"
                  value={inputs.name.value}
                  onChange={onValueChangeHandler}
                />
              </Grid>

              {/* code */}
              <Grid item md={6}>
                <TextField
                  required
                  inputProps={{
                    style: { textTransform: "uppercase" },
                  }}
                  error={inputs.code.error}
                  helperText={inputs.code.errorMessage}
                  margin="normal"
                  fullWidth
                  id="code"
                  label="Batch Code"
                  placeholder="Enter Batch Code"
                  name="code"
                  value={inputs.code.value}
                  onChange={onValueChangeHandler}
                />
              </Grid>

              <Grid item md={12}>
                <h3>Selected Students - {selectedStudents.length} </h3>
                <TextField
                  margin="normal"
                  fullWidth
                  id="search"
                  variant="standard"
                  label="Search By Keyword"
                  name="search"
                  value={searchKeyword.student}
                  onChange={(e) => onChangeSearchKeyword("student", e)}
                />
                {students && students.length > 0 && (
                  <Box mt={2}>
                    <TableContainer component={Paper} sx={{ mt: 4 }}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(studentRowsPerPage > 0
                            ? students.slice(
                                studentPage * studentRowsPerPage,
                                studentPage * studentRowsPerPage +
                                  studentRowsPerPage
                              )
                            : students
                          ).map((student, index) => {
                            let { firstName, lastName, email, _id } = student;
                            let name = `${firstName} ${lastName}`;
                            return (
                              <TableRow
                                key={_id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>
                                  <FormGroup>
                                    <FormControlLabel
                                      checked={_.includes(
                                        selectedStudents,
                                        _id
                                      )}
                                      onChange={(e) =>
                                        onClickCheckbox("student", _id)
                                      }
                                      control={<Checkbox />}
                                      // label={`${email} -  ${firstName} ${lastName}`}
                                    />
                                  </FormGroup>
                                </TableCell>

                                <TableCell>{name}</TableCell>
                                <TableCell>{email}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[
                                10,
                                20,
                                50,
                                { label: "All", value: -1 },
                              ]}
                              count={students.length}
                              rowsPerPage={studentRowsPerPage}
                              page={studentPage}
                              SelectProps={{
                                inputProps: {
                                  "aria-label": "rows per page",
                                },
                                native: true,
                              }}
                              onPageChange={(e, newPage) =>
                                handleChangePage(e, "student", newPage)
                              }
                              onRowsPerPageChange={(e, newPage) =>
                                handleChangeRowsPerPage(e, "student", newPage)
                              }
                              ActionsComponent={TablePaginationActions}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
                {(!students || students.length === 0) && (
                  <>
                    <br />
                    <h1 style={{ textAlign: "center" }}>No Students Found</h1>
                  </>
                )}
              </Grid>
              <Grid item md={12} mt={2}>
                <h3>Selected Trainers - {selectedTrainers.length}</h3>
                <TextField
                  margin="normal"
                  fullWidth
                  id="search"
                  variant="standard"
                  label="Search By Keyword"
                  name="search"
                  value={searchKeyword.trainer}
                  onChange={(e) => onChangeSearchKeyword("trainer", e)}
                />

                {trainers && trainers.length > 0 && (
                  <Box mt={2}>
                    <TableContainer component={Paper} sx={{ mt: 4 }}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(trainerRowsPerPage > 0
                            ? trainers.slice(
                                trainerPage * trainerRowsPerPage,
                                trainerPage * trainerRowsPerPage +
                                  trainerRowsPerPage
                              )
                            : students
                          ).map((trainer, index) => {
                            let { firstName, lastName, email, _id } = trainer;
                            let name = `${firstName} ${lastName}`;
                            return (
                              <TableRow
                                key={_id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>
                                  <FormGroup>
                                    <FormControlLabel
                                      checked={_.includes(
                                        selectedTrainers,
                                        _id
                                      )}
                                      onChange={(e) =>
                                        onClickCheckbox("trainer", _id)
                                      }
                                      control={<Checkbox />}
                                      // label={`${email} -  ${firstName} ${lastName}`}
                                    />
                                  </FormGroup>
                                </TableCell>

                                <TableCell>{name}</TableCell>
                                <TableCell>{email}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[
                                10,
                                20,
                                50,
                                { label: "All", value: -1 },
                              ]}
                              count={trainers.length}
                              rowsPerPage={trainerRowsPerPage}
                              page={trainerPage}
                              SelectProps={{
                                inputProps: {
                                  "aria-label": "rows per page",
                                },
                                native: true,
                              }}
                              onPageChange={(e, newPage) =>
                                handleChangePage(e, "trainer", newPage)
                              }
                              onRowsPerPageChange={(e, newPage) =>
                                handleChangeRowsPerPage(e, "trainer", newPage)
                              }
                              ActionsComponent={TablePaginationActions}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </Box>
                )}

                {(!trainers || trainers.length === 0) && (
                  <>
                    <br />
                    <h1 style={{ textAlign: "center" }}>No Trainers Found</h1>
                  </>
                )}
              </Grid>
              {/* submit button */}

              <LoadingButton
                type="submit"
                fullWidth
                loadingPosition="end"
                endIcon={<FaUsersViewfinder />}
                color="primary"
                loading={loading}
                loadingIndicator={"Adding..."}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {!loading && mode === "edit"
                  ? "PROCEED & UPDATE"
                  : "PROCEED & ADD"}
              </LoadingButton>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </section>
  );
};

export default CreateEditBatch;
